import FormUtils from '../helpers/form-utils';
import FormController from './form_controller';

const validationRules = {
  entry_shipper_attributes_name: {
    isValid: input => !!input.value,
    errorMessage: "Name can't be blank",
  },
  // entry_shipper_attributes_phone: {
  //   isValid: input => FormUtils.checkPhoneNumber(input.value).isValid,
  //   errorMessage: "Phone number must be a valid phone number",
  // },
  entry_shipper_attributes_address: {
    isValid: input => !!input.value,
    errorMessage: "Address can't be blank",
  },
  entry_consignee_attributes_name: {
    isValid: input => !!input.value,
    errorMessage: "Consignee name can't be blank",
  },
  // entry_consignee_attributes_phone: {
  //   isValid: input => FormUtils.checkPhoneNumber(input.value).isValid,
  //   errorMessage: "Phone number must be a valid phone number",
  // },
  entry_consignee_attributes_address: {
    isValid: input => !!input.value,
    errorMessage: "Address can't be blank",
  },
  entry_notify_attributes_name: {
    isValid: input => !!input.value,
    errorMessage: "Name can't be blank",
  },
  // entry_notify_attributes_phone: {
  //   isValid: input => FormUtils.checkPhoneNumber(input.value).isValid,
  //   errorMessage: "Phone number must be a valid phone number",
  // },
  entry_notify_attributes_address: {
    isValid: input => !!input.value,
    errorMessage: "Address can't be blank",
  },
  entry_bl_number: {
    isValid: input => !!input.value,
    errorMessage: "Bl number can't be blank",
  },
  entry_voyage_no: {
    isValid: input => !!input.value,
    errorMessage: "Voyage no can't be blank",
  },
  entry_carrier_id: {
    isValid: input => !!input.value,
    errorMessage: "Carrier can't be blank",
  },
  entry_vessel_id: {
    isValid: input => !!input.value,
    errorMessage: "Vessel can't be blank",
  },
  entry_etd: {
    isValid: input => !!input.value,
    errorMessage: "Etd can't be blank",
  },
  entry_eta: {
    isValid: input => !!input.value,
    errorMessage: "Eta can't be blank",
  },
  entry_country: {
    isValid: input => true,
  },
  entry_port_of_loading_id: {
    isValid: input => !!input.value,
    errorMessage: "Port of loading can't be blank",
  },
  entry_port_of_discharge_id: {
    isValid: input => !!input.value,
    errorMessage: "Port of discharge can't be blank",
  },
  entry_incoterm: {
    isValid: input => !!input.value,
    errorMessage: "Incoterm can't be blank",
  },
  entry_description_of_goods: {
    isValid: input => !!input.value,
    errorMessage: "Description of goods can't be blank",
  },
  entry_cost_of_goods: {
    isValid: input => !!input.value,
    errorMessage: "FOB value can't be blank",
  },
  entry_freight_value: {
    isValid: input => !!input.value,
    errorMessage: "Freight value can't be blank",
  },
  entry_insurance_value: {
    isValid: input => !!input.value,
    errorMessage: "Insurance value can't be blank",
  },
  entry_other_charges: {
    isValid: input => !!input.value,
    errorMessage: "Other charges can't be blank",
  },
  entry_volume: {
    isValid: input => !!input.value,
    errorMessage: "Volume can't be blank",
  },
  entry_shipment_type: {
    isValid: input => !!input.value,
    errorMessage: "Shipment Type can't be blank",
  },
  entry_bill_of_loading_doc: {
    isValid: input => true,
  },
  entry_commercial_invoice_doc: {
    isValid: input => true,
  },
  entry_customs_decleration_doc: {
    isValid: input => true,
  },
};

export default class NewEntryFormController extends FormController {

  static targets = [
    'costOfGoods',
    'freightValue',
    'insuranceValue',
    'otherCharges',
    'totalInvoiceAmount',
    'currencyOfGoods',
    'currencyOfFreight',
    'currencyOfInsurance',
    'currencyOfOtherCharges',
  ];

  constructor(context) {
    super(context, validationRules);
  }

  connect() {
    super.connect();
    this.updateTotal();
  }

  convertToNumber(str) {
    str = str.replace(/,/g, '');
    return parseFloat(str);
  }

  updateTotal() {
    const costOfGoods = this.convertToNumber(this.costOfGoodsTarget.value) || 0;
    const freightValue = this.convertToNumber(this.freightValueTarget.value) || 0;
    const insuranceValue = this.convertToNumber(this.insuranceValueTarget.value) || 0;
    const otherCharges = this.convertToNumber(this.otherChargesTarget.value) || 0;

    const total = costOfGoods + freightValue + insuranceValue + otherCharges;
    const amountSpan = this.totalInvoiceAmountTarget.querySelector('h6 span');

    const currencyOfGoods = this.currencyOfGoodsTarget.value;
    const currencyOfFreight = this.currencyOfFreightTarget.value;
    const currencyOfInsurance = this.currencyOfInsuranceTarget.value;
    const currencyOfOtherCharges = this.currencyOfOtherChargesTarget.value;
    let currency = '';

    if (currencyOfGoods === 'USD') {
      currency = '$';
    } else if (currencyOfGoods === 'EUR') {
      currency = '€';
    } else if (currencyOfGoods === 'GBP') {
      currency = '£';
    } else {
      currency = 'S';
    }

    if (
      currencyOfGoods === currencyOfFreight &&
      currencyOfGoods === currencyOfInsurance &&
      currencyOfGoods === currencyOfOtherCharges
    ) {
      amountSpan.innerText = currency + '' + total.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      this.totalInvoiceAmountTarget.style.display = 'block';
      amountSpan.style.color = 'black';
    } else {
      amountSpan.innerText = 'Different Currencies!';
      amountSpan.style.color = 'red';
    }

  }
}
